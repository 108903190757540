<template>
  <div style="padding: 10px">
    <!--    功能区域-->
<!--    <div style="margin: 10px 0">-->
<!--      <el-button type="primary" @click="add" v-if="user.role === 1">新增</el-button>-->

<!--    </div>-->

    <!--    搜索区域-->
    <div style="margin: 10px 0">

 <!--    功能区域-->
    <!-- <div style="margin: 10px 0">
      <el-input v-model="search" placeholder="请输入订单编号" style="width: 20%" clearable></el-input>
      <el-button type="primary" style="margin-left: 5px" @click="load">查询</el-button>
       <el-upload
                class="avatar-uploader"
                action="api/order/uploadFile"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload">
                <el-button type="primary">导入</el-button>
        </el-upload>
      
        <a href="/static/模板文件.xls"  rel="external nofollow"  download="下载">
              <el-button type="primary"  >下载模板文件</el-button>
        </a>
    </div> -->

 <div style="margin: 10px 0">
      <!-- <el-button type="primary" @click="add">新增</el-button> -->
        <el-input v-model="permanentId" placeholder="请输入永久id" style="width: 20%;margin-right: 10px;" clearable></el-input>
        <el-input v-model="prodBatch" placeholder="请输入产品批次" style="width: 20%" clearable></el-input>
      <el-button type="primary" style="margin-left: 5px" @click="load">查询</el-button>
      <!-- <el-upload
        action="api/order/uploadFile"
        :on-success="handleUploadSuccess"
        :show-file-list=false
        :limit="1"
        accept='.xlsx'
        style="display: inline-block; margin: 0 10px"
      >
        <el-button type="primary">导入</el-button>
      </el-upload> -->
      <!-- <a href="/static/模板文件.xls"  rel="external nofollow"  download="下载">
              <el-button type="primary"  >下载模板文件</el-button>
      </a> -->
      <el-button type="primary" @click="exportExcel">导出</el-button>
    </div>

<!--         
      <el-row>
       <el-col :span="4"><div style="width: 100%"> <el-input v-model="search" placeholder="请输入订单编号" style="width: 100%" clearable></el-input></div></el-col>
       <el-col :span="1"><div class="grid-content bg-purple-light"><el-button type="primary" style="margin-left: 5px" @click="load">查询</el-button></div></el-col>

        <el-col :span="1"><div>
          <el-upload
                class="avatar-uploader"
                action="api/order/uploadFile"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload">
                <el-button type="primary">导入</el-button>
            </el-upload>
          </div> 
        </el-col>

        <el-col :span="1">
            <a href="/static/模板文件.xls"  rel="external nofollow"  download="下载">
              <el-button type="primary"  >下载模板文件</el-button>
            </a>
        </el-col>
      </el-row> -->
    </div>
    <el-table
        v-loading="loading"
        :data="tableData"
        border
        stripe
        style="width: 100%"
    >
      <el-table-column
          width="200"
          prop="prodId"
          label="产品ID"
          sortable
      >
      </el-table-column>
      <el-table-column
          width="150"
          prop="permanentId"
          label="永久id">
      </el-table-column>
      <el-table-column
          width="160"
          prop="prodBatch"
          label="产品批次">
      </el-table-column>
      <el-table-column
          prop="prodDwNo"
          width="200"
          label="产品图号">
      </el-table-column>
      <el-table-column
          prop="prodNo"
          width="200"
          label="产品型号">
      </el-table-column>
      <el-table-column
        width="200"
        label="产品图片图片"
      >
        <template #default="scope">
          <el-image 
            fit="contain"
            style="width: 100px; height: 100px"
            :src="scope.row.prodImageOne" 
            :preview-src-list="[scope.row.prodImageOne]">
          </el-image>
        </template>
      </el-table-column>
      <el-table-column
          width="200"
          label="永久id图片"
        >
          <template #default="scope">
            <el-image 
              fit="contain"
              style="width: 100px; height: 100px"
              :src="scope.row.prodImageTwo" 
              :preview-src-list="[scope.row.prodImageTwo]">
            </el-image>
          </template>
      </el-table-column>
      <el-table-column
          width="200"
          prop="prodImageThree"
          label="实拍产品"
        >
        <template #default="scope">
            <el-image 
              fit="contain"
              style="width: 100px; height: 100px"
              :src="scope.row.prodImageThree" 
              :preview-src-list="[scope.row.prodImageThree]">
            </el-image>
          </template>
      </el-table-column>
      <!-- <el-table-column
          prop="username"
          label="购买人">
      </el-table-column>
      <el-table-column label="支付状态">
        <template #default="scope">
          <span v-if="scope.row.state === 0" style="color: orange">未支付</span>
          <span v-if="scope.row.state === 1" style="color: green">已支付</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="240">
        <template #default="scope">
          <el-popconfirm title="确定删除吗？" @confirm="handleDelete(scope.row.id)">
            <template #reference>
              <el-button size="mini" type="danger">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column> -->
    </el-table>
<!-- 
   <div style="margin: 10px 0">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[5, 10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div> -->
  <div style="margin: 0 auto 10px auto">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[5, 10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
  </div>
   
  </div>

   
   
</template>

<script>


import request from "@/utils/request";

export default {
  name: 'Order',
  components: {

  },
  data() {
    return {
      user: {},
      loading: true,
      form: {},
      dialogVisible: false,
      search: '',
      permanentId:'',
      prodBatch:'',
      currentPage: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
    }
  },
  created() {
    let userStr = sessionStorage.getItem("user") || "{}"
    this.user = JSON.parse(userStr)
    // 请求服务端，确认当前登录用户的 合法信息
    request.get("/user/" + this.user.id).then(res => {
      if (res.code === '0') {
        this.user = res.data
      }
    })

    this.load()
  },
  methods: {
    //上传成功后触发的方法
    handleAvatarSuccess(res, file) {
        this.imageUrl=res.data;
    },
    //上传前触发的方法
    beforeAvatarUpload(file) {
        const fileType = file.type === 'file/xlsx'|| 'file/xls';
        // const isLt2M = file.size / 1024 / 1024 < 2;
        // if (!isJPG) {
        //     this.$message.error('上传头像图片只能是 JPG 格式!');
        // }
        // if (!isLt2M) {
        //     this.$message.error('上传头像图片大小不能超过 2MB!');
        // }
        if (!fileType) {
            this.$message.error('导入的文件只能是 xls | xlsx');
        }
        return fileType;
    },
    load() {
      this.loading = true
      request.post(`/api/getOrderList?pageNum=${this.currentPage}&pageSize=${this.pageSize}&permanentId=${this.permanentId}&prodBatch=${this.prodBatch}`, {
        // params: {
        //   pageNum: this.currentPage,
        //   pageSize: this.pageSize,
        //   search: this.search
        // }
      }).then(res => {
        this.loading = false
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    add() {
      this.dialogVisible = true
      this.form = {}
      if (this.$refs['upload']) {
        this.$refs['upload'].clearFiles()  // 清除历史文件列表
      }
    },
    save() {
      if (this.form.id) {  // 更新
        request.put("/order", this.form).then(res => {
          console.log(res)
          if (res.code === '0') {
            this.$message({
              type: "success",
              message: "更新成功"
            })
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.load() // 刷新表格的数据
          this.dialogVisible = false  // 关闭弹窗
        })
      }  else {  // 新增
        request.post("/order", this.form).then(res => {
          console.log(res)
          if (res.code === '0') {
            this.$message({
              type: "success",
              message: "新增成功"
            })
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }

          this.load() // 刷新表格的数据
          this.dialogVisible = false  // 关闭弹窗
        })
      }
    },
    handleEdit(row) {
      this.form = JSON.parse(JSON.stringify(row))
      this.dialogVisible = true
      this.$nextTick(() => {
        if (this.$refs['upload']) {
          this.$refs['upload'].clearFiles()  // 清除历史文件列表
        }
      })

    },
    handleDelete(id) {
      console.log(id)
      request.delete("/order/" + id).then(res => {
        if (res.code === '0') {
          this.$message({
            type: "success",
            message: "删除成功"
          })
        } else {
          this.$message({
            type: "error",
            message: res.msg
          })
        }
        this.load()  // 删除之后重新加载表格的数据
      })
    },
    handleSizeChange(pageSize) {   // 改变当前每页的个数触发
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {  // 改变当前页码触发
      this.currentPage = pageNum
      this.load()
    },
    exportExcel(){
      request({
        method: 'post',
        url: `/api/getOrderList?pageNum=${this.currentPage}&pageSize=${this.pageSize}&type=export&permanentId=${this.permanentId}&prodBatch=${this.prodBatch}`,
        responseType: 'blob'
      })
      .then(function (response) {
        // response.data.pipe(fs.createWriteStream('ada_lovelace.jpg'))
        //根据bolb下载文件
        const blob = new Blob([response], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"})
        const downloadElement = document.createElement('a')
        const href = window.URL.createObjectURL(blob) 
        downloadElement.href = href
        downloadElement.download = '订单列表.xlsx' 
        document.body.appendChild(downloadElement)
        downloadElement.click()
        document.body.removeChild(downloadElement) 
        window.URL.revokeObjectURL(href) 

      });

      //  request.post(`/api/getOrderList?pageNum=${this.currentPage}&pageSize=${this.pageSize}&type=export`).then(()=> {
      //   this.$message({
      //         type: "success",
      //         message: "导出成功"
      //       })
      // })
    }
  }
}
</script>
